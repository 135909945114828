import React, { useEffect } from 'react'
import queryString from 'query-string'

function App () {
  useEffect(() => {
    const urlParsed = queryString.parse(window.location.search)
    const { terminal, language } = urlParsed
    if (terminal && language) {
      window.location.replace(
        `https://web.visitorhsse.com/${language}/${terminal}/auth/login`
      )
    }
  }, [])

  return (
    <img
      style={{ height: '100vh', width: '100%', objectFit: 'contain' }}
      src={require('./assets/img/bg_temp.png')}
      alt=''
    />
  )
}

export default App
